import type { Repository, RouteArgs } from "@octopusdeploy/octopus-server-client";
import React from "react";
interface OctopusClientContextValue {
    repository: Repository;
    resolvePath: (path: string, uriTemplateParameters?: RouteArgs) => string;
}
const OctopusClientContext = React.createContext<OctopusClientContextValue | undefined>(undefined);
export interface OctopusClientProviderProps extends OctopusClientContextValue {
    children: React.ReactNode;
}
export function OctopusClientProvider({ repository, resolvePath, children }: OctopusClientProviderProps) {
    const contextValue = React.useMemo(() => ({
        repository,
        resolvePath,
    }), [repository, resolvePath]);
    return <OctopusClientContext.Provider value={contextValue}>{children}</OctopusClientContext.Provider>;
}
export function useOctopusRepository() {
    const contextValue = React.useContext(OctopusClientContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusClientProvider");
    }
    return contextValue.repository;
}
export function useOctopusPathResolver() {
    const contextValue = React.useContext(OctopusClientContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusClientContext");
    }
    return contextValue.resolvePath;
}
